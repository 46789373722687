#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  /* padding-bottom: 50px; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: auto;
  /* margin-top: 100px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.img-plate {
  -webkit-animation: anim 10s infinite linear;
  animation: anim 10s infinite linear;
}

@-webkit-keyframes anim {
  from {-webkit-transform: rotateY(0deg);}
  to {-webkit-transform: rotateY(360deg);}
}

@keyframes anim {
  from {transform: rotateY(0deg);}
  to {transform: rotateY(360deg);}
}