@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Poppins;
}

::-webkit-scrollbar {
  display: none;
}

.home-slider-bg {
  background-image: url('/src/ui/assets/home/home-slider-bg.png');
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  height: max-content;
}

.bluecol {
  color: #13104B;
  /* color:#323131; */
}

.redcol {
  color: #F1592A;
}

.blueback {
  background: #1D1964;
  border: 1px solid #1D1964 !important;
}

.whiteback {
  background: #fff;
  /* border: 1px solid #1D1964 !important; */
}

@media only screen and (max-width: 425px) {
  .banner-image {
    height: 100px;
  }
}
.terms{
  padding: 50px;
  font-family: Poppins;

}
.terms ul li{
  list-style: none;
  font-family: Poppins;
  margin-top: 10px;

}
.terms h2{
  margin-top: 15px;
}
.terms p{
  margin-top: 15px;

}
@media (max-width: 768px){
  .terms{
    padding: 30px;
}
}